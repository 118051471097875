import { IAction } from './IActions';
import { ITbFormat } from '../../components/Editor/IEditor';
import { IReceived, ISend, IWsProps } from '../../components/Libraries/ILibraries';
import { IAppliedFilter } from '../../components/DashboardHeader/ISearch';
import {
  AnimButtonStatesEnum,
  EditorModeEnums,
  EditorVariantEnum,
  ILoadContentToEditor,
  ISnack,
  IUser,
  IWsAction,
  IAddColumnMenu,
  IUpdateEditorState,
  ISessionLabel,
  ISortObject,
  IPageable,
  DashboardRefreshState,
  IHeader,
  IHeaderWidth,
  ISpeakersModal,
  IDictionary,
  IHomeFlowStates,
  IConsumtionModal,
  ISpeakersSettingsModal,
} from '../store/IStore';
import { IConfig, ISttStatus } from '../../components/Home/ISettings';
import { IAddToDictWord, IPronunciation } from '../../api/DictionaryService';
import { IActiveUserQuotaChunk } from '../../api/UserService';

export const SET_VARIANT = 'SET_VARIANT';
export const SET_DO_DICTATION = 'SET_DO_DICTATION';
export const SET_IS_TRANSCRIPT = 'SET_IS_TRANSCRIPT';
export const SET_IS_PLAYING = 'SET_IS_PLAYING';
export const SET_CURRENTTIME = 'SET_CURRENTTIME';
export const SET_AUDIO_INFO = 'SET_AUDIO_INFO';
export const SET_TB_FORMAT = 'SET_TB_FORMAT';
export const SET_YOUTUBE_LINK = 'SET_YOUTUBE_LINK';
export const SET_SHOW_CONFIDENCE = 'SET_SHOW_CONFIDENCE';
export const SET_EDITOR_MODE = 'SET_EDITOR_MODE';
export const SET_CLICKED_TIME = 'SET_CLICKED_TIME';
export const SET_FONT_SIZE = 'SET_FONT_SIZE';
export const SET_HEADER_IS_EXTENDED = 'SET_HEADER_IS_EXTENDED';
export const SET_MESSAGE_TO_SEND = 'SET_MESSAGE_TO_SEND';
export const SET_RECEIVED_MESSAGE = 'SET_RECEIVED_MESSAGE';
export const SET_WS_PROPS = 'SET_WS_PROPS';
export const SET_WS_CONNECTED = 'SET_WS_CONNECTED';
export const SET_SESSION_NAME = 'SET_SESSION_NAME';
export const SET_IS_SESSION_DISCARDED = 'SET_IS_SESSION_DISCARDED';
export const SET_ACTIVE_WORD_ID = 'SET_ACTIVE_WORD_ID';
export const SET_MAKE_BLOCKS = 'SET_MAKE_BLOCKS';
export const SET_SAVE_TO_DISK = 'SET_SAVE_TO_DISK';
export const SET_USER = 'SET_USER';
export const SET_AUTH_OK_GET_CONFIG = 'SET_AUTH_OK_GET_CONFIG';
export const SET_SESSION_ID = 'SET_SESSION_ID';
export const SET_URL_NAME = 'SET_URL_NAME';
export const SET_IS_LIVE_MODE = 'SET_IS_LIVE_MODE';
export const SET_RECORDING_LIVE = 'SET_RECORDING_LIVE';
export const SET_ANIM_BUTTON_STATE = 'SET_ANIM_BUTTON_STATE';
export const SET_CHECK_WEB_SOCKET_STATE = 'SET_CHECK_WEB_SOCKET_STATE';
export const SET_LAST_ORIGINAL_TEXT = 'SET_LAST_ORIGINAL_TEXT';
export const SET_CONFIG = 'SET_CONFIG';
export const SET_STT_STATUS = 'SET_STT_STATUS';
export const SET_SNACK = 'SET_SNACK';
export const SET_WS_ACTION = 'SET_WS_ACTION';
export const SET_ENTITIES_KEYS_TO_MERGE = 'SET_ENTITIES_KEYS_TO_MERGE';
export const SET_LOAD_CONTENT_TO_EDITOR = 'SET_LOAD_CONTENT_TO_EDITOR';
export const SET_D_MODEL_ID = 'SET_D_MODEL_ID';
export const SET_VALID_REDIRECT = 'SET_VALID_REDIRECT';
export const SET_UPLOADED_FILE_DURATION = 'SET_UPLOADED_FILE_DURATION';
export const SET_REFETCH_TOKENS = 'SET_REFETCH_TOKENS';
export const SET_FONTS_LOADED = 'SET_FONTS_LOADED';
export const SET_DASHBOARD_FILTERS = 'SET_DASHBOARD_FILTERS';
export const SET_ADD_COLUMN = 'SET_ADD_COLUMN';
export const SET_MANUALLY_UPDATE_EDITOR_STATE = 'SET_MANUALLY_UPDATE_EDITOR_STATE';
export const SET_DASHBOARD_EXPENDED_ROW = 'SET_DASHBOARD_EXPENDED_ROW';
export const SET_SESSION_LABELS = 'SET_SESSION_LABELS';
export const SET_DASHBOARD_REFRESH = 'SET_DASHBOARD_REFRESH';
export const SET_DASHBOARD_SORT = 'SET_DASHBOARD_SORT';
export const SET_DASHBOARD_PAGINATION = 'SET_DASHBOARD_PAGINATION';
export const SET_DASHBOARD_HEADERS = 'SET_DASHBOARD_HEADERS';
export const SET_DASHBOARD_TABLE_WIDTH = 'SET_DASHBOARD_TABLE_WIDTH';
export const SET_DASHBOARD_TABLE_REFRESH = 'SET_DASHBOARD_TABLE_REFRESH';
export const SET_DASHBOARD_HEADER_WIDTHS = 'SET_DASHBOARD_HEADER_WIDTHS';
export const SET_DASHBOARD_HEADER_FILTERS = 'SET_DASHBOARD_HEADER_FILTERS';
export const RESET_DASHBOARD_HEADERS = 'RESET_DASHBOARD_HEADERS';
export const SET_SPEAKERS_MODAL = 'SET_SPEAKERS_MODAL';
export const SET_IS_AUDIO_PLAYING = 'SET_IS_AUDIO_PLAYING';
export const SET_DASHBAORD_FILTER_COMMANDS = 'SET_DASHBAORD_FILTER_COMMANDS';
export const SET_DICTIONARY = 'SET_DICTIONARY';
export const SET_DICTIONARY_WORDS = 'SET_DICTIONARY_WORDS';
export const REMOVE_PRONAUN_FROM_WORD = 'REMOVE_PRONAUN_FROM_WORD';
export const SET_DICT_WORD_PRONAUN = 'SET_DICT_WORD_PRONAUN';
export const SET_ADD_PRONAUN = 'SET_ADD_PRONAUN';
export const SET_HOME_FLOW_STATE = 'SET_HOME_FLOW_STATE';
export const SET_SELECTED_FRAMEWORK = 'SET_SELECTED_FRAMEWORK';
export const SET_ACTIVE_QUOTA = 'SET_ACTIVE_QUOTA';
export const SET_CONSUMPTION_MODAL = 'SET_CONSUMPTION_MODAL';
export const SET_DIARIZATION = 'SET_DIARIZATION';
export const SET_SPEAKER_UPDATED = 'SET_SPEAKER_UPDATED';
export const SET_ROW_OPENING = 'SET_ROW_OPENING';
export const SET_SPEAKER_SETTINGS_MODAL = 'SET_SPEAKER_SETTINGS_MODAL';
export const SET_TRIGGER_STOP_RECORDING = 'SET_TRIGGER_STOP_RECORDING';


export const setHomeFlowState = (homeFlowStates: IHomeFlowStates): IAction => {
  return { type: SET_HOME_FLOW_STATE, homeFlowStates };
};

export const setDashboardFilterCommands = (dashboardFilterCommands: any): IAction => {
  return { type: SET_DASHBAORD_FILTER_COMMANDS, dashboardFilterCommands };
};

export const setDictionary = (dictionary: Partial<IDictionary>): IAction => {
  return { type: SET_DICTIONARY, dictionary };
};

export const setWordsInDict = (newWords: IAddToDictWord[] | undefined): IAction => {
  return { type: SET_DICTIONARY_WORDS, newWords };
};

export const setWordPronaun = (pronaunData: {
  pronaun: IPronunciation | null;
  pronaunIndex: number;
  wordIndex: number;
}): IAction => {
  return { type: SET_DICT_WORD_PRONAUN, pronaunData };
};

export const setAddPronaun = (addPronaunData: { pronaun: IPronunciation; wordIndex: number }): IAction => {
  return { type: SET_ADD_PRONAUN, addPronaunData };
};

export const setWsConnected = (wsConnected: boolean): IAction => {
  return { type: SET_WS_CONNECTED, wsConnected };
};

export const setManuallyUpdateEditorState = (manuallyUpdateEditorState: IUpdateEditorState): IAction => {
  return { type: SET_MANUALLY_UPDATE_EDITOR_STATE, manuallyUpdateEditorState };
};

export const setValidRedirect = (validRedirect: boolean): IAction => {
  return { type: SET_VALID_REDIRECT, validRedirect };
};

export const setEntitiesKeysToMerge = (entitiesKeysToMerge: string[]): IAction => {
  return { type: SET_ENTITIES_KEYS_TO_MERGE, entitiesKeysToMerge };
};

export const setWsAction = (wsAction: IWsAction): IAction => {
  return { type: SET_WS_ACTION, wsAction };
};

export const setWsProps = (wsProps: IWsProps): IAction => {
  return { type: SET_WS_PROPS, wsProps };
};

export const setDoDictation = (doDictation: boolean): IAction => {
  return { type: SET_DO_DICTATION, doDictation };
};

export const setReceivedMessage = (receivedMessage: IReceived | null): IAction => {
  return { type: SET_RECEIVED_MESSAGE, receivedMessage };
};

export const setMessageToSend = (messageToSend: ISend | undefined): IAction => {
  return { type: SET_MESSAGE_TO_SEND, messageToSend };
};

export const setLoadContentToEditor = (loadContentToEditor: ILoadContentToEditor | undefined): IAction => {
  return { type: SET_LOAD_CONTENT_TO_EDITOR, loadContentToEditor };
};

export const setYoutubeLink = (youtubeLink: string | null): IAction => {
  return { type: SET_YOUTUBE_LINK, youtubeLink };
};

export const setVariant = (variant: EditorVariantEnum): IAction => {
  return { type: SET_VARIANT, variant };
};
export const setFontsLoaded = (fontsLoaded: boolean): IAction => {
  return { type: SET_FONTS_LOADED, fontsLoaded };
};

export const setTbFormat = (tbFormat: ITbFormat): IAction => {
  return { type: SET_TB_FORMAT, tbFormat };
};

export const setIsTranscript = (isTranscript: boolean): IAction => {
  return { type: SET_IS_TRANSCRIPT, isTranscript };
};

export const setIsPlaying = (isPlaying: boolean): IAction => {
  return { type: SET_IS_PLAYING, isPlaying };
};

export const setCurrentTime = (currentTime: number): IAction => {
  return { type: SET_CURRENTTIME, currentTime };
};

export const setUploadedFileDuration = (uploadedFileDuration: number | null): IAction => {
  return { type: SET_UPLOADED_FILE_DURATION, uploadedFileDuration };
};

export const setAudioInfo = (audioInfo: { url?: string; loadNew?: boolean }): IAction => {
  return { type: SET_AUDIO_INFO, audioInfo };
};
export const setIsAudioPlaying = (isAudioPlaying: boolean): IAction => {
  return { type: SET_IS_AUDIO_PLAYING, isAudioPlaying };
};

export const setShowConfidence = (showConfidence: boolean): IAction => {
  return { type: SET_SHOW_CONFIDENCE, showConfidence };
};

export const setRefetchTokens = (refetchTokens: number | null): IAction => {
  return { type: SET_REFETCH_TOKENS, refetchTokens };
};

export const setEditorMode = (editorMode: EditorModeEnums | null): IAction => {
  return { type: SET_EDITOR_MODE, editorMode };
};

export const setClickedTime = (clickedTime: number | null): IAction => {
  return { type: SET_CLICKED_TIME, clickedTime };
};

export const setFontSize = (fontSize: string): IAction => {
  return { type: SET_FONT_SIZE, fontSize };
};

export const setHeaderIsExtended = (headerIsExtended: boolean): IAction => {
  return { type: SET_HEADER_IS_EXTENDED, headerIsExtended };
};

export const setSessionName = (sessionName: string): IAction => {
  return { type: SET_SESSION_NAME, sessionName };
};

export const setIsSessionDiscarded = (isSessionDiscarded: boolean): IAction => {
  return { type: SET_IS_SESSION_DISCARDED, isSessionDiscarded };
};

export const setUrlName = (urlName: string): IAction => {
  return { type: SET_URL_NAME, urlName };
};

export const setActiveWordId = (activeWordId: string): IAction => {
  return { type: SET_ACTIVE_WORD_ID, activeWordId };
};

export const setMakeBlocks = (makeBlocks: boolean): IAction => {
  return { type: SET_MAKE_BLOCKS, makeBlocks };
};

export const setSaveToDisk = (saveToDisk: boolean): IAction => {
  return { type: SET_SAVE_TO_DISK, saveToDisk };
};

export const setUser = (user: null | IUser): IAction => {
  return { type: SET_USER, user };
};

export const setAuthOkGetConfig = (authOkGetConfig: boolean): IAction => {
  return { type: SET_AUTH_OK_GET_CONFIG, authOkGetConfig };
};

export const setSessionId = (sessionId: string): IAction => {
  return { type: SET_SESSION_ID, sessionId };
};

export const setDModelId = (dModelId: number): IAction => {
  return { type: SET_D_MODEL_ID, dModelId };
};

export const setIsLiveMode = (isLiveMode: boolean): IAction => {
  return { type: SET_IS_LIVE_MODE, isLiveMode };
};

export const setRecordingLive = (recordingLive: string): IAction => {
  return { type: SET_RECORDING_LIVE, recordingLive };
};

export const setAnimButtonState = (animButtonState: AnimButtonStatesEnum): IAction => {
  return { type: SET_ANIM_BUTTON_STATE, animButtonState };
};

export const setCheckWebSocketState = (checkWebSocketState: boolean): IAction => {
  return { type: SET_CHECK_WEB_SOCKET_STATE, checkWebSocketState };
};

export const setLastOriginalText = (lastOriginalText: string): IAction => {
  return { type: SET_LAST_ORIGINAL_TEXT, lastOriginalText };
};

export const setConfig = (config: IConfig | null): IAction => {
  return { type: SET_CONFIG, config };
};

export const setSttStatus = (sttStatus: ISttStatus | null): IAction => {
  return { type: SET_STT_STATUS, sttStatus };
};

export const setSnack = (snack: ISnack): IAction => {
  return { type: SET_SNACK, snack };
};

export const setDashboardFilters = (dashboardFilters: IAppliedFilter[]): IAction => {
  return { type: SET_DASHBOARD_FILTERS, dashboardFilters };
};

export const setAddColumnAnchor = (addColumnsMenu: IAddColumnMenu): IAction => {
  return { type: SET_ADD_COLUMN, addColumnsMenu };
};

export const setDashboardExpendedRow = (dashboardExpendedRow: number): IAction => {
  return { type: SET_DASHBOARD_EXPENDED_ROW, dashboardExpendedRow };
};

export const setSessionsLabels = (sessionsLabels: ISessionLabel[]): IAction => {
  return { type: SET_SESSION_LABELS, sessionsLabels };
};

export const setDashboardRefresh = (dashboardRefresh: DashboardRefreshState): IAction => {
  return { type: SET_DASHBOARD_REFRESH, dashboardRefresh };
};

export const setDashboardSort = (dashboardSort: ISortObject): IAction => {
  return { type: SET_DASHBOARD_SORT, dashboardSort };
};

export const setDashboardPagination = (dashboardPagination: Partial<IPageable>): IAction => {
  return { type: SET_DASHBOARD_PAGINATION, dashboardPagination };
};

export const setDashboardHeader = (dashboardHeader: IHeader): IAction => {
  return { type: SET_DASHBOARD_HEADERS, dashboardHeader };
};

export const resetDashboardHeader = (dashboardHeader: IHeader): IAction => {
  return { type: RESET_DASHBOARD_HEADERS, dashboardHeader };
};

export const setDashboardTableWidth = (dashboardTableWidth: number): IAction => {
  return { type: SET_DASHBOARD_TABLE_WIDTH, dashboardTableWidth };
};

export const setDashboardTableRefresh = (dashboardTableRefresh: DashboardRefreshState): IAction => {
  return { type: SET_DASHBOARD_TABLE_REFRESH, dashboardTableRefresh };
};

export const setDashboardHeaderWidths = (dashboardHeaderWidths: IHeaderWidth): IAction => {
  return { type: SET_DASHBOARD_HEADER_WIDTHS, dashboardHeaderWidths };
};

export const setDashboardHEaderFilter = (dashboardHeaderFilters: IAppliedFilter[]): IAction => {
  return { type: SET_DASHBOARD_HEADER_FILTERS, dashboardHeaderFilters };
};

export const setSpeakersModal = (speakersModal: ISpeakersModal): IAction => {
  return { type: SET_SPEAKERS_MODAL, speakersModal };
};

export const setActiveQuota = (activeQuota: IActiveUserQuotaChunk | null): IAction => {
  return { type: SET_ACTIVE_QUOTA, activeQuota };
};

export const setConsumptionModal = (consumptionModal: IConsumtionModal): IAction => {
  return { type: SET_CONSUMPTION_MODAL, consumptionModal };
};

export const setDiarization = (diarization: boolean): IAction => {
  return { type: SET_DIARIZATION, diarization };
};

export const setSpeakerUpdated = (speakerUpdated: boolean): IAction => {
  return { type: SET_SPEAKER_UPDATED, speakerUpdated };
};

export const setRowOpening = (rowOpening: boolean): IAction => {
  return { type: SET_ROW_OPENING, rowOpening };
};

export const setSpeakerSettingsModal = (speakerSettingsModal: Partial<ISpeakersSettingsModal>): IAction => {
  return { type: SET_SPEAKER_SETTINGS_MODAL, speakerSettingsModal };
};

export const setTriggerStopRecording = (triggerStopRecording: boolean): IAction => {
  return { type: SET_TRIGGER_STOP_RECORDING, triggerStopRecording };
}




//Config actions

/*export const setTranscriptionLanguage = (language: string): IConfig => {
  return { type: SET_TRANSCRIPTION_LANGUAGE, language }
}*/
