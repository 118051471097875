import { ISpeaker, ITbFormat } from '../../components/Editor/IEditor';
import { IConfig, ISttStatus } from '../../components/Home/ISettings';
import { IAppliedFilter } from '../../components/DashboardHeader/ISearch';
import { Map, Attributes, ColumnTypes } from '../../components/Dashboard/IDashboard';
import { SelectionState } from 'draft-js';
import { IPhonems } from '../../components/DictionaryModal/IDictionaryModal';
import { IContextString } from '../../components/Dictionary/ContextLine';
import { IAddToDictWord } from '../../api/DictionaryService';
import { IActiveUserQuotaChunk } from '../../api/UserService';

export interface ISnack {
  open: boolean;
  message: string;
  variant: 'success' | 'error';
}

export enum UserRoleEnums {
  ADMIN = 'ADMIN',
  API_USER = 'API_USER',
  DICTIONARY_READER = 'DICTIONARY_READER',
  DICTIONARY_WRITER = 'DICTIONARY_WRITER',
  EDITOR_USER = 'EDITOR_USER',
  FILE_UPLOADER = 'FILE_UPLOADER',
  GROUP_ADMIN = 'GROUP_ADMIN',
  HARD_DELETER = 'HARD_DELETER',
  LIVE_STREAMER = 'LIVE_STREAMER',
  MODEL_UPDATER_READER = 'MODEL_UPDATER_READER', //this one gets model info in future
  MODEL_UPDATER_WRITER = 'MODEL_UPDATER_WRITER', //this one gets model info in future
  offline_access = 'offline_access',
  SUBSTITUTOR_READER = 'SUBSTITUTOR_READER',
  uma_authorization = 'uma_authorization',
}
export interface IUser {
  isAuthenticated: boolean;
  username: string;
  accessToken?: string;
  refreshToken?: string;
  userRoles: UserRoleEnums[];
  isEditorUser?: boolean;
}

export enum IWsAction {
  IDLE = 'IDLE',
  OPEN = 'OPEN',
  CLOSE = 'CLOSE',
  RECONNECT = 'RECONNECT',
}

export enum FontSize {
  Biggest = '22px',
  Big = '18px',
  Medium = '14px',
  Small = '10px',
}

export enum EditorModeEnums {
  EDIT_MODE = 'EDIT_MODE',
  PLAYING_MODE = 'PLAYING_MODE',
  RECORDING_MODE = 'RECORDING_MODE',
  TRANSCRIBING_UPLOAD_MODE = 'TRANSCRIBING_UPLOAD_MODE',
  TB_UPLOAD_MODE = 'TB_UPLOAD_MODE',
}

export enum AnimButtonStatesEnum {
  NORMAL = 'NORMAL',
  WAITING_TO_START = 'WAITING_TO_START',
  WAITING_TO_STOP = 'WAITING_TO_STOP',
  RECORDING = 'RECORDING',
}

export enum EditorVariantEnum {
  LIVE = 'LIVE',
  EDITOR = 'EDITOR',
}

export interface ILoadContentToEditor {
  recFinishedStartLoadingNewEditorState?: boolean;
  recStartedLoadTextFromEditor?: boolean;
}

export interface IRefectTokens {
  refetchCount: number;
  refetchTime: number | null;
}

export interface IAddColumnMenu {
  anchor: null | SVGSVGElement;
  id: string;
}

export interface IUpdateEditorState {
  update: boolean;
  newText?: string;
  rangeToReplace?: number[];
  entityToMerge?: string;
  blockKey?: string;
}

export interface ISessionLabel {
  id: number;
  code: string;
  isDefault: boolean;
  isAssigned: boolean;
  color: string;
}
export interface ISortObject {
  columnName: string | null;
  type: 'asc' | 'desc' | null;
}

export interface IPageable {
  offset: number;
  pageNumber: number;
  pageSize: number;
  paged: boolean;
  totalElements: number;
}

// Also used for table (only normal & none)
export enum DashboardRefreshState {
  NORMAL = 'NORMAL', // We wish to refresh the dashboard
  ZERO = 'ZERO', // We wish to refresh the dashboard, but also set pagination to 0
  NONE = 'NONE', // We don't need to refresh the dashboard
}

export interface IHeaderColumn {
  id: Attributes;
  width: number;
  order: number; // in which order are headers displayed
  shown: boolean;
  filtered: boolean;
  sortable: boolean;
  label: string;
  type: ColumnTypes;
}

export enum SpeakersModalTypeEnum {
  NORMAL = 'NORMAL',
  ADD_SPEAKER_TO_SELECTION = 'ADD_SPEAKER_TO_SELECTION',
}

export interface ISpeakersModal {
  showModal: boolean;
  speaker: ISpeaker | null;
  blockKey: string | null;
  modalType: SpeakersModalTypeEnum;
  editorSelection: SelectionState | null;
  // blockData: any;
}

export type IHeaderWidth = Map<number>;
export type IHeader = Map<IHeaderColumn>;

export interface IDictionary {
  phones?: IPhonems | null;
  contextString: IContextString | null;
  searchBy: 'word' | 'pronunciation';
  searchedString: null | string;
  caseSensitive: boolean;
  pageSize: number;
  words: IAddToDictWord[] | undefined;
  autoSave: IAddToDictWord | null;
}

export interface IHomeFlowStates {
  uploadFlowInProgress?: boolean;
  liveFlowInProgress?: boolean;
}

export enum ConsumtionModalTemplate {
  UPLOAD_LIMIT = "UPLOAD_LIMIT",
  LIMIT = "LIMIT"
}
export interface IConsumtionModal {
  visible: boolean;
  template: ConsumtionModalTemplate | undefined;
}

export interface ISpeakersSettingsModal {
  visible: boolean;
  numberOfSpeakers: number | null;
  minNumberOfSpeakers: number | null;
  maxNumberOfSpeakers: number | null;
  retranscription: boolean;
}

export interface IStore {
  //   variant: EditorVariantEnum;
  doDictation: boolean;
  fontsLoaded: boolean;
  dictionary: IDictionary;
  isTranscript: boolean;
  isPlaying: boolean;
  dashboardFilterCommands: any;
  isAudioPlaying: boolean;
  loadContentToEditor: ILoadContentToEditor;
  currentTime: number;
  uploadedFileDuration: number | null;
  speakersModal: ISpeakersModal;
  audioInfo: {
    url: string;
    loadNew: boolean;
  };
  manuallyUpdateEditorState: IUpdateEditorState;
  refetchTokens: {
    refetchCount: 0;
    refetchTime: null;
  };
  homeFlowStates: IHomeFlowStates;
  tbFormat: ITbFormat;
  youtubeLink: string | null;
  showConfidence: boolean;
  editorMode: EditorModeEnums | null;
  diarization: boolean;
  clickedTime: number | null;
  fontSize: string;
  headerIsExtended: boolean;
  //   receivedMessage: IReceived | null;
  //   messageToSend: ISend | null;
  //   wsProps: IWsProps;
  //   wsConnected: boolean | null;
  sessionName: string;
  isSessionDiscarded: boolean;
  activeWordId: string;
  makeBlocks: boolean;
  saveToDisk: boolean;
  user: IUser | null;
  authOkGetConfig: boolean;
  config: IConfig | null;
  sttStatus: ISttStatus | null;
  sessionId: string;
  urlName: string;
  //   isLiveMode: boolean;
  //   recordingLive: string;
  animButtonStates: AnimButtonStatesEnum;
  //   checkWebSocketState: boolean;
  lastOriginalText: string;
  snack: ISnack;
  wsAction: IWsAction;
  entitiesKeysToMerge: string[];
  //   dModelId: number;
  validRedirect: boolean;
  dashboardFilters: IAppliedFilter[];
  addColumnsMenu: IAddColumnMenu;
  dashboardExpendedRow: number | null;
  sessionsLabels: ISessionLabel[];
  dashboardRefresh: DashboardRefreshState;
  dashboardSort: ISortObject;
  dashboardPagination: IPageable;
  dashboardHeader: IHeader | null;
  dashboardHeaderWidths: IHeaderWidth | null;
  dashboardTableWidth: number | null;
  dashboardTableRefresh: DashboardRefreshState;
  activeQuota: IActiveUserQuotaChunk | null;
  consumptionModal: IConsumtionModal;
  speakerUpdated: boolean;
  rowOpening: boolean;
  speakerSettingsModal: ISpeakersSettingsModal;
  triggerStopRecording: boolean;
}
